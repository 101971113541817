import React from 'react'
import { graphql } from 'gatsby'

import SEO from '../components/SEO/SEO'
import DynamicZone from '../components/DynamicZone/DynamicZone'

export default function Home({ data: { strapi: { homepage } }, location }) {

	return (
		<>
			<SEO title="Home" description={ homepage.seoDescription } pathname={ location.pathname } />

			{ homepage && homepage.content &&
				<DynamicZone components={ homepage.content } />
			}
		</>
	)
}

export const query = graphql`
	query homepageQuery {
		strapi {
			homepage {
				seoDescription
				content {
					__typename
					... on StrapiQuery_ComponentHomepageHomepageHero {
						id
						heading
						image {
							url
							imageFile {
								childImageSharp {
									fluid(maxWidth: 1000) {
										...GatsbyImageSharpFluid_noBase64
									}
								}
							}
						}
					}
					... on StrapiQuery_ComponentHomepageQuote {
						id
						author
						quote
					}
					... on StrapiQuery_ComponentHomepageThumbnail {
						id
						textBlock
						thumbnail {
							url
							imageFile {
								childImageSharp {
									fluid(maxWidth: 1000) {
										...GatsbyImageSharpFluid_noBase64
									}
								}
							}
						}
					}
					... on StrapiQuery_ComponentHomepageCompanies {
						id
						heading
						companies {
							id
							url
							name
							logo {
								url
								imageFile {
									childImageSharp {
										fluid(maxWidth: 1000) {
											...GatsbyImageSharpFluid_noBase64
										}
									}
								}
							}
						}
					}
					... on StrapiQuery_ComponentCommonStatement {
						id
						statement
						url
						urlText
					}
					... on StrapiQuery_ComponentCommonVideo {
						videoNumber
						videoTitle
					}
					... on StrapiQuery_ComponentCommonTestimonial {
						id
						introduction
						testimonial {
							slug
							thumbnail {
								url
								imageFile {
									childImageSharp {
										fluid(maxWidth: 400) {
											...GatsbyImageSharpFluid_noBase64
										}
									}
								}
							}
						}
					}
					... on StrapiQuery_ComponentCommonContactForm {
						id
						message
					}
				}
			}
		}
	}
`